import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { Layout, VideoCard } from 'components';

import bg from 'images/job-videos.png';
import botHintArrow from 'images/bot-hint-arrow.png';

const StyledJobVideosHeader = styled.div`
  background: var(--dark-blue);
  background: radial-gradient(
    circle at 89% 111%,
    var(--dark-sky-blue),
    #122348 38%
  );

  .bg {
    background: url(${bg}) left bottom no-repeat;
    background-position-x: 5%;
    background-size: 288px;
  }
`;

const BgText = styled.div`
  h3 {
    position: relative;
    font-family: 'Sansation Regular', sans-serif;
    font-weight: 400;
    margin: 0;
    line-height: 40px;
    img {
      width: 38px;
      padding-top: 20px;
      margin-left: 20px;
    }
  }
`;

const VideosPage = ({
  data: {
    prismicJobVideosPage: {
      data: { header_text, job_videos },
    },
  },
}) => {
  return (
    <Layout>
      <StyledJobVideosHeader>
        <div className="uk-container uk-container-medium uk-padding-large bg">
          <div className="uk-grid-small" uk-grid="true">
            <div className="uk-width-3-5@l uk-width-1-2@m uk-width-1-1 uk-text-left@m uk-text-center uk-margin-small-bottom uk-light">
              <h1>Videos</h1>
            </div>
            <div className="uk-width-2-5@l uk-width-1-2@m uk-width-1-1  uk-text-left@m uk-text-center uk-margin-small-bottom uk-light">
              <BgText className="uk-text-right@m uk-text-center">
                <h3>
                  {header_text.text}
                  <img src={botHintArrow} />
                </h3>
              </BgText>
            </div>
          </div>
        </div>
      </StyledJobVideosHeader>

      <div className="uk-container uk-padding-large">
        <div
          className="uk-child-width-1-3@m uk-child-width-1-2@s uk-grid-medium uk-grid-match"
          uk-grid="true"
          uk-scrollspy="target: > div; cls: uk-animation-slide-bottom-medium; delay: 200; offset-top: -200;"
        >
          {job_videos.map((job_video, i) => (
            <VideoCard {...job_video} key={`job-video-${i}`} />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default VideosPage;

export const query = graphql`
  query {
    prismicJobVideosPage {
      data {
        header_text {
          text
        }
        job_videos {
          youtube_id
          job_title
          company
          location
          job_video_image {
            localFile {
              childImageSharp {
                fixed(quality: 80, height: 200, width: 380, cropFocus: CENTER) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`;
